import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
					<path d="M5892 10537 c-55 -20 -120 -79 -147 -132 -33 -67 -40 -122 -24 -187
7 -29 12 -60 11 -69 0 -9 10 -21 23 -27 13 -6 28 -20 34 -30 13 -24 1 -36 -44
-46 -57 -14 -300 -109 -380 -148 -149 -75 -360 -207 -462 -290 -204 -166 -244
-206 -454 -451 -72 -84 -339 -539 -339 -577 0 -7 -11 -38 -24 -69 -56 -128
-119 -370 -153 -586 -27 -171 -24 -544 5 -737 23 -156 25 -174 17 -166 -14 13
-65 290 -81 433 -25 235 -12 496 37 738 23 113 81 313 109 374 11 24 20 47 20
53 0 6 16 42 35 80 20 39 45 94 56 122 23 59 130 240 164 278 13 14 39 51 59
82 20 31 41 60 47 63 5 4 24 26 40 48 86 121 286 310 449 427 30 22 60 43 65
47 13 11 78 52 141 92 86 53 317 163 419 200 78 28 87 32 83 37 -5 4 -185 -57
-263 -89 -59 -24 -222 -110 -305 -160 -115 -70 -168 -107 -280 -196 -94 -75
-390 -364 -390 -381 0 -5 -13 -24 -29 -42 -80 -91 -295 -463 -349 -603 -117
-303 -177 -586 -188 -895 -13 -343 47 -690 178 -1030 37 -95 155 -336 184
-375 12 -16 43 -65 69 -109 126 -214 385 -488 647 -684 226 -169 555 -343 798
-422 197 -64 301 -91 475 -121 114 -21 163 -23 395 -24 304 0 407 12 655 76
315 81 557 187 815 360 252 167 512 419 692 667 52 72 177 282 231 387 54 106
117 248 117 263 0 6 6 26 14 44 34 79 85 281 121 479 18 99 38 455 30 551 -8
99 -34 293 -51 378 -107 524 -401 1044 -788 1392 -60 54 -117 98 -127 98 -10
0 -39 18 -64 40 -25 22 -49 40 -53 40 -10 0 -64 -59 -92 -100 -14 -19 -35 -43
-47 -53 -31 -25 -29 -40 10 -69 47 -36 192 -182 262 -263 33 -38 68 -79 79
-90 34 -37 183 -269 234 -365 127 -238 233 -555 263 -790 20 -162 27 -479 12
-600 -14 -111 -50 -303 -73 -385 -6 -22 -16 -57 -21 -79 -5 -21 -16 -51 -24
-67 -8 -15 -15 -36 -15 -46 0 -23 -80 -208 -127 -293 -70 -127 -186 -316 -222
-360 -20 -25 -50 -63 -66 -85 -52 -71 -308 -318 -411 -397 -101 -77 -287 -194
-396 -249 -83 -42 -254 -111 -378 -152 -272 -90 -673 -136 -950 -108 -140 14
-377 55 -451 77 -399 121 -677 279 -949 539 -244 234 -487 587 -584 850 -15
39 -30 79 -36 90 -19 42 -69 214 -94 329 -30 134 -66 426 -61 498 2 26 5 98 6
160 5 234 46 451 130 693 46 133 58 163 92 233 19 37 40 83 47 102 22 54 70
132 200 325 79 116 235 292 366 410 95 85 264 211 354 263 19 11 71 41 115 66
121 70 482 216 534 216 4 0 45 -23 90 -50 93 -57 153 -74 220 -64 96 14 166
64 222 157 30 51 36 56 74 60 l40 4 -43 1 -42 2 1 43 c3 70 -37 165 -91 215
-11 10 -72 47 -135 81 -109 58 -117 61 -182 60 -37 0 -83 -6 -101 -12z"/>
					<path d="M5625 10110 l-20 -9 20 0 c11 0 27 4 35 9 13 9 13 10 0 9 -8 0 -24
-4 -35 -9z"/>
					<path d="M6120 9730 c-125 -12 -139 -16 -152 -45 -12 -27 1 -145 28 -254 17
-70 25 -77 65 -61 28 12 33 12 45 -5 8 -10 14 -26 14 -35 0 -9 6 -25 14 -35
12 -17 18 -18 52 -7 22 6 84 24 139 41 113 34 200 52 365 78 141 21 512 23
668 4 99 -13 134 -9 102 11 -166 101 -391 196 -589 249 -200 53 -536 79 -751
59z"/>
					<path d="M5577 9605 c-135 -50 -436 -220 -540 -305 -60 -49 -121 -103 -164
-146 -50 -49 -53 -53 -53 -103 0 -31 9 -74 23 -109 13 -31 43 -108 67 -169 24
-62 49 -113 56 -113 6 0 17 7 24 15 20 24 40 17 56 -20 20 -48 18 -69 -12
-104 l-25 -30 36 -83 c39 -88 130 -264 172 -332 61 -99 122 -187 178 -261 34
-44 66 -87 71 -96 15 -27 123 -147 216 -241 57 -57 93 -86 102 -82 19 7 96
-72 96 -97 0 -10 6 -22 14 -26 7 -4 42 -34 77 -67 35 -32 100 -86 144 -119 44
-34 82 -64 85 -67 18 -23 193 -145 336 -235 202 -128 305 -188 479 -279 138
-72 190 -105 181 -114 -18 -18 -514 212 -766 356 -141 81 -420 261 -474 309
-30 25 -48 33 -65 30 -29 -6 -81 40 -81 70 0 10 -37 50 -85 92 -271 236 -594
627 -745 901 -38 69 -60 82 -90 55 -10 -9 -24 -14 -29 -10 -16 9 -13 57 4 79
24 31 19 52 -43 185 -33 69 -68 150 -78 180 -10 29 -25 56 -32 59 -7 2 -26
-10 -41 -28 -31 -38 -36 -35 -62 40 -9 25 -22 45 -30 45 -16 0 -55 -58 -128
-190 -77 -139 -129 -285 -182 -512 l-19 -82 36 -113 c39 -123 50 -151 118
-305 25 -56 46 -105 46 -109 0 -27 217 -393 303 -510 165 -226 431 -514 494
-535 20 -6 38 -21 43 -34 12 -31 107 -105 334 -260 72 -49 125 -90 119 -92
-15 -5 -200 88 -308 153 -146 90 -184 118 -353 263 -228 196 -426 423 -626
718 -40 59 -77 108 -84 111 -7 2 -12 -15 -14 -45 l-3 -48 -48 73 c-47 72 -73
85 -63 34 33 -172 66 -293 110 -409 84 -218 142 -324 303 -552 41 -58 246
-272 308 -321 121 -97 226 -176 288 -216 245 -159 496 -259 832 -330 149 -31
421 -43 572 -25 473 59 864 232 1216 539 90 79 243 257 323 377 105 156 221
391 274 555 105 323 126 769 50 1080 -56 231 -109 378 -199 553 -56 110 -144
247 -158 247 -3 0 -3 -22 0 -50 3 -27 2 -52 -2 -54 -5 -3 -20 16 -35 42 -14
26 -40 63 -56 82 l-31 35 7 -45 c3 -25 10 -62 15 -82 15 -57 39 -333 39 -433
-1 -101 -24 -395 -39 -495 -24 -152 -80 -376 -132 -525 -6 -16 -17 -51 -25
-77 -8 -26 -19 -50 -25 -54 -7 -4 -9 0 -5 12 19 64 73 350 93 494 22 156 24
551 4 680 -39 245 -96 450 -162 586 -33 67 -42 73 -73 49 -30 -23 -39 -19 -65
30 -13 25 -28 45 -33 45 -9 0 -58 -35 -105 -75 -7 -5 -33 -26 -59 -45 -124
-94 -228 -179 -334 -275 -30 -28 -84 -75 -120 -105 -36 -29 -72 -61 -81 -69
-24 -24 -171 -157 -199 -180 l-25 -20 95 -93 c128 -126 180 -172 345 -311 17
-14 27 -29 23 -33 -8 -7 -99 53 -243 159 -43 32 -92 66 -107 75 -15 9 -28 20
-28 24 0 4 -19 12 -42 18 -23 7 -58 23 -77 36 -20 13 -41 24 -47 24 -10 0 -75
-65 -306 -303 -38 -38 -68 -65 -68 -60 0 8 55 89 70 103 3 3 31 37 62 76 31
39 63 77 70 85 7 8 29 34 48 59 19 25 41 51 48 59 20 21 45 61 41 66 -207 211
-283 292 -324 339 -46 53 -62 73 -127 158 -11 15 -51 76 -87 135 l-67 108 -44
0 c-39 0 -47 4 -68 35 -15 22 -31 34 -42 33 -25 -3 -192 -79 -250 -113 -26
-15 -58 -31 -71 -34 -58 -17 65 68 311 213 60 36 65 42 64 73 -1 18 -11 59
-23 92 -12 32 -33 99 -46 149 -30 112 -40 124 -89 107 -43 -14 -51 -10 -66 35
-13 39 -30 43 -93 20z m2283 -2525 c-16 -31 -23 -18 -8 15 7 15 14 23 16 17 2
-6 -2 -21 -8 -32z"/>
					<path d="M6415 9226 c-107 -14 -165 -26 -257 -52 -72 -21 -73 -33 -20 -142
119 -238 400 -617 445 -599 9 3 32 -13 58 -39 24 -24 48 -44 54 -44 6 0 45 37
86 82 101 113 409 421 549 551 108 101 114 108 96 122 -10 8 -38 17 -62 21
-28 4 -54 16 -74 35 -16 16 -33 29 -37 29 -5 0 -60 9 -123 21 -147 26 -560 35
-715 15z"/>
					<path d="M9885 7819 c-22 -6 -53 -10 -70 -9 -226 8 -260 2 -310 -65 -46 -60
-50 -81 -88 -490 -2 -22 -20 -93 -41 -157 -20 -64 -36 -127 -36 -140 0 -13 -4
-28 -10 -34 -16 -16 -92 -275 -85 -291 3 -10 -3 -22 -15 -30 -22 -16 -90 -142
-90 -168 0 -9 -16 -29 -35 -46 -51 -46 -88 -128 -88 -197 2 -131 65 -199 215
-232 49 -11 57 -16 62 -40 4 -23 22 -36 106 -79 55 -29 126 -59 157 -67 50
-13 58 -13 74 2 23 21 89 147 89 171 0 10 18 36 39 57 108 106 125 296 34 378
-30 28 -104 55 -167 63 -46 5 -59 11 -63 26 -4 12 -35 36 -82 60 -56 29 -67
38 -42 33 19 -3 43 -1 56 6 18 10 38 8 111 -11 65 -17 118 -23 195 -24 100 0
108 2 149 29 56 37 84 70 115 133 73 151 44 279 -81 362 -43 28 -126 58 -252
91 -50 13 -70 46 -49 81 11 20 19 21 111 16 87 -5 102 -8 116 -27 20 -27 77
-39 181 -40 52 0 80 4 88 13 15 18 44 332 39 428 -4 92 -34 146 -101 180 -48
26 -167 34 -232 18z m-234 -920 c106 -33 119 -40 119 -65 0 -29 -11 -30 -105
-6 -70 18 -85 32 -71 68 6 17 13 17 57 3z"/>
					<path d="M2908 7618 c-27 -9 -28 -13 -28 -76 0 -37 5 -101 11 -142 6 -41 11
-84 11 -95 0 -11 6 -84 14 -162 8 -78 14 -149 14 -157 0 -30 30 -46 83 -45 90
2 111 10 117 44 5 28 19 37 188 120 101 50 187 97 192 105 6 11 -12 313 -19
327 -18 35 -95 43 -166 18 -34 -12 -52 -12 -143 3 -96 15 -105 18 -118 44 -13
26 -19 28 -72 27 -31 0 -69 -5 -84 -11z"/>
					<path d="M3440 7021 c-59 -21 -69 -28 -80 -54 -6 -17 -32 -30 -107 -55 -85
-29 -102 -32 -125 -21 -23 10 -36 8 -87 -11 -33 -12 -62 -29 -65 -37 -8 -20
112 -433 132 -455 8 -10 25 -18 36 -18 36 0 209 65 219 82 15 23 -20 146 -49
175 -13 12 -20 24 -16 26 34 19 119 36 177 36 75 -1 138 18 149 47 6 16 -68
272 -82 285 -17 16 -61 16 -102 0z"/>
					<path d="M3321 6392 c-69 -42 -69 -45 -16 -162 71 -160 89 -171 180 -122 37
20 51 34 53 53 4 33 -90 234 -116 248 -30 16 -52 12 -101 -17z"/>
					<path d="M3713 6255 c-48 -32 -68 -59 -61 -83 2 -6 -39 -39 -91 -72 -84 -55
-97 -60 -117 -50 -19 11 -30 8 -78 -21 -31 -19 -59 -44 -62 -55 -4 -15 14 -55
57 -130 98 -173 111 -181 191 -125 39 27 54 44 56 65 3 23 20 38 95 87 50 32
97 59 104 59 8 0 13 -13 12 -37 -1 -34 -9 -45 -81 -110 -65 -59 -87 -73 -113
-73 -26 0 -42 -10 -79 -50 -45 -48 -46 -51 -32 -77 8 -16 34 -51 58 -78 53
-63 89 -106 217 -261 92 -113 133 -154 154 -154 4 0 30 21 57 46 49 46 62 78
39 101 -7 7 -1 43 22 121 l32 111 31 -6 c17 -3 46 -14 64 -24 46 -27 63 -24
102 16 45 46 50 37 10 -18 -23 -32 -31 -53 -29 -76 5 -43 -120 -222 -153 -218
-26 3 -98 -86 -98 -121 0 -27 14 -40 193 -180 264 -204 289 -222 308 -222 10
0 36 22 60 52 35 44 40 56 34 85 -5 29 2 43 61 125 36 51 67 94 69 96 7 8 55
-20 55 -32 0 -7 -21 -59 -47 -114 -39 -83 -51 -101 -73 -105 -20 -5 -31 -18
-50 -64 -29 -67 -28 -88 3 -114 33 -29 413 -219 437 -219 17 0 31 22 70 104
28 57 50 113 50 124 0 23 -44 58 -120 97 -30 15 -57 29 -59 31 -2 2 7 28 20
59 18 43 31 60 54 69 41 17 90 129 71 161 -7 11 -82 56 -168 100 -86 44 -175
96 -199 116 -24 20 -74 58 -110 85 -36 27 -110 83 -165 125 -115 88 -140 103
-161 95 -12 -5 -14 -1 -9 18 5 20 -4 37 -41 83 -25 32 -49 60 -52 63 -4 3 -62
73 -130 155 -68 83 -130 155 -138 161 -7 6 -13 19 -13 29 0 31 -133 252 -161
269 -36 21 -37 21 -96 -19z m802 -1105 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9
10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-125 -180 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
					<path d="M8691 5789 c-72 -85 -111 -139 -111 -154 0 -26 93 -115 121 -115 9 0
48 -48 94 -117 72 -109 77 -119 67 -146 -6 -16 -9 -34 -6 -41 9 -23 92 -85
136 -102 84 -31 76 -35 178 86 52 62 109 127 125 145 71 78 95 105 168 193 42
51 77 98 77 104 0 19 -103 108 -130 114 -14 2 -38 9 -54 14 -22 7 -36 6 -52
-5 -21 -13 -35 -9 -181 51 -177 74 -264 104 -298 104 -16 0 -51 -34 -134 -131z"/>
					<path d="M8230 5360 c-224 -172 -240 -185 -240 -207 0 -18 159 -213 174 -213
8 0 26 -6 42 -14 31 -17 45 -12 105 35 l37 29 31 -35 c35 -40 37 -48 16 -65
-24 -20 -18 -41 31 -102 32 -41 53 -58 69 -58 12 0 28 -7 35 -15 7 -8 20 -15
30 -15 9 0 78 46 153 103 109 81 137 107 137 126 0 36 -77 131 -112 138 -16 3
-31 10 -34 14 -3 5 -15 9 -27 9 -15 0 -34 15 -54 41 l-32 41 62 47 c86 66 86
76 -7 193 -58 73 -83 97 -106 102 -16 3 -42 8 -57 12 -24 4 -58 -18 -253 -166z"/>
					<path d="M7640 5006 c-132 -62 -155 -77 -158 -97 -3 -29 41 -134 62 -146 10
-5 22 -52 37 -146 l22 -138 -22 -15 c-11 -8 -21 -24 -21 -36 0 -28 60 -138 75
-138 7 0 24 -9 38 -20 14 -11 30 -20 36 -20 6 0 72 30 148 67 76 36 158 74
183 83 101 39 297 139 303 154 4 10 -7 45 -26 84 -27 56 -37 68 -64 75 -18 4
-33 12 -33 17 0 6 -10 10 -23 10 -17 0 -60 39 -161 148 -78 82 -151 152 -167
158 -16 5 -34 15 -41 22 -7 7 -17 12 -22 12 -6 0 -81 -34 -166 -74z"/>
					<path d="M5412 4853 c-14 -27 -33 -116 -30 -138 2 -11 -26 -70 -61 -132 -58
-101 -68 -112 -95 -115 -37 -4 -54 -33 -63 -108 -5 -46 -3 -52 22 -76 27 -25
127 -59 260 -89 33 -8 131 -34 218 -59 86 -26 163 -46 171 -46 24 0 44 34 57
98 l13 64 -31 26 -30 27 -12 219 c-7 121 -14 223 -16 227 -13 19 -101 53 -220
84 -151 40 -171 42 -183 18z"/>
					<path d="M6925 4776 c-77 -17 -146 -35 -152 -39 -17 -11 -16 -62 2 -125 12
-40 21 -53 40 -58 27 -7 28 -9 64 -164 l20 -84 -20 -19 c-16 -15 -19 -26 -13
-50 19 -86 31 -112 57 -125 15 -7 27 -17 27 -21 0 -22 42 -18 188 15 172 40
233 65 290 122 63 63 77 103 77 217 -1 89 -3 106 -29 155 -34 68 -57 94 -122
136 -70 45 -161 74 -231 73 -32 0 -121 -15 -198 -33z m245 -254 c5 -20 9 -50
9 -67 l-1 -30 -8 30 c-22 84 -27 114 -19 110 4 -3 13 -22 19 -43z"/>
					<path d="M6092 4730 c-116 -47 -168 -192 -107 -299 30 -52 30 -51 2 -51 -41 0
-47 -17 -47 -139 l0 -116 34 -33 35 -33 103 1 c66 1 123 -5 158 -15 64 -18 96
-19 153 -4 100 25 167 103 167 197 0 51 -26 122 -50 137 -17 10 -11 23 12 27
14 2 25 13 29 28 9 34 10 222 1 236 -4 5 -24 20 -44 33 -34 20 -49 22 -135 20
-57 -1 -110 3 -126 9 -33 14 -152 15 -185 2z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
